import React from "react";

import Page from "lib/@cms/components/layout/Page";
import PageHeader from "lib/@cms/components/cms/PageHeader";
import ContentBox from "lib/@cms/components/shared/ContentBox";
import Divider from "lib/@cms/components/primitive/Divider";

function FAQsPage() {
  return (
    <Page pathname="faqs">
      {data => {
        return (
          <React.Fragment>
            <PageHeader
              variant={PageHeader.variant.GRADIENTS}
              color={PageHeader.color.PRIMARY}
              data={data.PageHeaderGradients}
            />

            <div className="tw-container">
              {data &&
                data.Faqs?.map((element, index) => (
                  <div key={`BodyList-${index}`}>
                    <ContentBox title={element.title}>
                      <ul>
                        {element.list?.map((item, index1) => {
                          return (
                            <li key={`BodyList-${index1}`} className="tw-mb-5">
                              {item.item}
                            </li>
                          );
                        })}
                      </ul>
                    </ContentBox>
                    <Divider className="tw-my-6" />
                  </div>
                ))}
              <Divider className="tw-my-16 sm:tw-my-24" />
            </div>
          </React.Fragment>
        );
      }}
    </Page>
  );
}

export default FAQsPage;
